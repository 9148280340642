import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

import styles from './StaffMember.module.scss'

const StaffMember = ({ description, name, picture }) => (
  <div className={styles.staffMember}>
    <div className={styles.picture}>
      <Image fluid={picture} alt={name} />
    </div>

    <div className={styles.content}>
      <h2 className="t-heading-s">{name}:</h2>
      <p>“{description}”</p>
    </div>
  </div>
)

StaffMember.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  picture: PropTypes.any.isRequired,
}

export default StaffMember
