import React from 'react'
import rehypeReact from 'rehype-react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import MdLink from '$components/utility/MdLink'
import Meta from '$components/common/Meta'
import SplitContent from '$components/common/SplitContent'
import StaffMember from '$components/pageOver/StaffMember'

// prettier-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: MdLink,
    h2: (props) => <h2 {...props} className="t-heading-l">{props.children}</h2>,
    h3: (props) => <h3 {...props} className="t-heading-m">{props.children}</h3>,
  }
}).Compiler

const Over = ({ data: { over } }) => (
  <BackgroundImage
    critical
    className="contentBackgroundImage"
    fluid={over.frontmatter.background.childImageSharp.fluid}
    style={{ height: '100%' }}
  >
    <Meta
      title={over.frontmatter.title}
      description={over.frontmatter.description}
    />

    <SplitContent
      title={over.frontmatter.title}
      image={over.frontmatter.background.childImageSharp.fluid}
      backgroundColor={over.frontmatter.backgroundColor}
      contentLeft={renderAst(over.htmlAst)}
      contentRight={over.frontmatter.staff.map((staffMember) => (
        <StaffMember
          key={staffMember.name}
          description={staffMember.description}
          name={staffMember.name}
          picture={staffMember.picture.childImageSharp.fluid}
        />
      ))}
    />
  </BackgroundImage>
)

export default Over

export const query = graphql`
  query Over($locale: String!) {
    over: markdownRemark(
      frontmatter: { title: { eq: "Over WillemUitvaart" } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      htmlAst
      frontmatter {
        title
        description
        backgroundColor
        staff {
          name
          description
          picture {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        background {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
