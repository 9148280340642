import React from 'react'
import PropTypes from 'prop-types'

import styles from './SplitContent.module.scss'

const SplitContent = ({
  backgroundColor,
  contentLeft,
  contentRight,
  title,
}) => {
  return (
    <>
      <div className={styles.splitContent} style={{ backgroundColor }}>
        <div className="wrapper-m">
          <div className={styles.contentLeft}>
            <h1 className="t-heading-l">{title}</h1>
            {contentLeft}
          </div>

          <div className={styles.contentRight}>{contentRight}</div>
        </div>
      </div>
    </>
  )
}

SplitContent.propTypes = {
  backgroundColor: PropTypes.string,
  contentLeft: PropTypes.any.isRequired,
  contentRight: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
}

export default SplitContent
