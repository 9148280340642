import React from 'react'
import LocalizedLink from '$components/common/LocalizedLink'

const isHash = (str) => /^#/.test(str)
const isInternal = (to) => /^\/(?!\/)/.test(to)

const MdLink = ({ href, ...props }) =>
  isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  ) : (
    <LocalizedLink {...props} to={href} />
  )

export default MdLink
